export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const formatCurrency = (value: number, currency = "EUR") => {
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency,
  }).format(value);
};

export const getCurrencyIcon = (currency: string) => {
  return formatCurrency(0, currency).slice(0, -4);
};

export const validatePhone = (phoneNumber: string) => {
  const pattern = /^[0-9]{10}$/;
  return pattern.test(phoneNumber);
};

export const formatLabel = (str: string) => {
  return str
    .replace(/_/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .toLowerCase();
};

export const validateAlphaNumeric = (str: string) => {
  const pattern = /^[a-zA-Z0-9]*$/;
  return pattern.test(str);
};

export const copyToClipboard = (str = "") => {
  navigator.clipboard.writeText(str);
};

export const saveFile = async (file: Blob, name: string) => {
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = fileURL;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const openFile = (file: Blob) => {
  const fileURL = URL.createObjectURL(file);
  console.log(fileURL);
  const res = window.open(fileURL, "_blank");
  console.log(res);
};
export const fileToBase64 = (file?: File) => {
  if (!file) return null;
  const reader = new FileReader();
  const base64 = new Promise<string>((res, rej) => {
    reader.onload = function (e) {
      const result = e.target?.result;
      if (typeof result !== "string") {
        return rej("Invalid file type");
      }
      res(result.split(",")[1]);
    };
    reader.onerror = function (error) {
      rej(error);
    };
  });
  reader.readAsDataURL(file);

  return base64;
};
