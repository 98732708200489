import AOS from "aos";
import "aos/dist/aos.css";
import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";
import Footer from "./Components/Footer";
import Hero from "./Components/Hero";
import Navbar from "./Components/Navbar";
import ProductTab from "./Components/ProductTab";
import Report from "./Components/Report";
import { CRISP_ID } from "./constants";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    Crisp.configure(CRISP_ID);
  }, []);
  return (
    <div>
      <Navbar />
      <Hero />

      <ProductTab />

      {/* <TrustedBy /> */}
      {/* <CustomerFeedback /> */}

      <Report />

      {/* <Slider /> */}
      {/* <Security /> */}

      {/* <News /> */}
      {/* <CustomerReview /> */}

      <Footer />
    </div>
  );
}

export default App;
