import { formatLabel } from "@/utils/helper";
import { AiTwotoneBank } from "react-icons/ai";
import { FaReceipt } from "react-icons/fa";
import { IoCardSharp } from "react-icons/io5";
import { TbFileInvoice, TbReceiptTax } from "react-icons/tb";
import Dropdown from "./Dropdown";
import ThemeToggle from "./ThemeToggle";
const productCards = [
  {
    name: "invoice",
    Icon: TbFileInvoice,
  },
  {
    name: "bill",
    Icon: TbReceiptTax,
  },
  {
    name: "expense",
    Icon: FaReceipt,
  },
  {
    name: "bank_integration",
    Icon: AiTwotoneBank,
  },
  {
    name: "card_issuing",
    Icon: IoCardSharp,
  },
];

const Navbar = () => {
  return (
    <div className="lg:px-56 navbar glass z-10 sticky top-0  ">
      <div className="navbar-start">
        <div className="dropdown">
          <button
            tabIndex={0}
            role="button"
            className="btn btn-ghost lg:hidden"
            name="button"
            aria-label="menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </button>
          <ul className="menu menu-sm  dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
            {productCards.map(({ Icon, name }) => (
              <li key={name}>
                <button className="capitalize">
                  <Icon />
                  {formatLabel(name)}
                </button>
              </li>
            ))}
            <li>
              <ThemeToggle />
            </li>
          </ul>
        </div>
        <a href="/" className="font-extrabold text-2xl text-base-content">
          Dynt
        </a>
      </div>
      <div className="navbar-center hidden lg:flex">
        <Dropdown
          title="Products"
          hover
          dropdownClass="w-fit"
          buttonClass="btn-ghost "
          content={productCards.map(({ Icon, name }) => (
            <span className="capitalize flex items-center gap-3 ">
              <Icon />
              {formatLabel(name)}
            </span>
          ))}
        />
        <ul className="menu items-center menu-horizontal px-1">
          <li className="">
            <a href="/">Customers</a>
          </li>
          <li>
            <a href="/">Pricing</a>
          </li>
        </ul>
      </div>
      <div className="navbar-end flex gap-2">
        <a
          target="_blank"
          href="https://app.dynt.ai/login"
          className="btn md:flex  hidden btn-outline btn-secondary"
        >
          Login
        </a>
        <a
          target="_blank"
          className="btn btn-primary"
          href="https://app.dynt.ai/register/sign-up"
        >
          Register
        </a>
      </div>
      <div className="hidden lg:flex absolute right-20">
        <ThemeToggle />
      </div>
    </div>
  );
};

export default Navbar;
