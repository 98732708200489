const Footer = () => {
  return (
    <footer className="footer p-10 glass ">
      <aside>
        <a
          href="/"
          className="btn btn-ghost btn-square btn-md rounded-none font-extrabold text-2xl text-base-content"
        >
          Dynt
        </a>
      </aside>
      <nav>
        <header className="footer-title">Products</header>
        <a href="/" className="link link-hover">
          Invoice
        </a>
        <a href="/" className="link link-hover">
          Bill
        </a>
        <a href="/" className="link link-hover">
          Expense
        </a>
        <a href="/" className="link link-hover">
          Card Issuing
        </a>
      </nav>
      <nav>
        <header className="footer-title">Company</header>
        <a href="/" className="link link-hover">
          About us
        </a>
        <a href="/" className="link link-hover">
          Contact
        </a>
        <a href="/" className="link link-hover">
          Jobs
        </a>
        <a href="/" className="link link-hover">
          Press kit
        </a>
      </nav>
      <nav>
        <header className="footer-title">Legal</header>
        <a href="/" className="link link-hover">
          Terms of use
        </a>
        <a href="/" className="link link-hover">
          Privacy policy
        </a>
        <a href="/" className="link link-hover">
          Cookie policy
        </a>
      </nav>
    </footer>
  );
};

export default Footer;
