import mockup from "../assets/images/PNG_10.png";

const Hero = () => {
  return (
    <section className="hero bg-base-200 min-h-screen">
      <div className="hero-content text-center py-8 md:py-12  scroll-mt-16  flex-col gap-12 pt-12 md:max-h-[calc(100vw-80px)] md:gap-16">
        <div className="max-w-lg">
          <h1
            className="lg:text-5xl font-bold text-4xl"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Take charge of your company spend.
          </h1>
          <p
            className="py-6 lg:text-base text-sm"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Less paperwork, but more control over spend, faster month-end, and
            more accurate reporting.
          </p>
          <div className="join" data-aos="fade-up" data-aos-delay="400">
            <button className="btn join-item btn-primary">Get Started</button>
            <button className="btn join-item btn-secondary btn-outline">
              See how it works
            </button>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-delay="500">
          <picture>
            <source media="(max-width: 680px)" srcSet={mockup} />
            <img alt="Dynt back-office" className="mx-auto" srcSet={mockup} />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default Hero;
