import { FC, ReactNode } from "react";

type Props = {
  title: string;
  content: ReactNode[];
  hover?: boolean;
  dropdownClass?: string;
  buttonClass?: string;
};

const Dropdown: FC<Props> = ({
  content,
  title,
  hover,
  dropdownClass = "",
  buttonClass = "",
}) => {
  const handleClick = () => {
    const elem = document.activeElement;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (elem) elem.blur();
  };

  return (
    <div
      className={`dropdown z-10 w-52 ${dropdownClass} ${
        hover ? "dropdown-hover" : ""
      } `}
    >
      <div tabIndex={0} role="button" className={`btn m-1 ${buttonClass} `}>
        {title}
      </div>
      <ul className="dropdown-content mx-auto z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
        {content.map((c, i) => (
          <li key={i} onClick={handleClick}>
            {c}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
