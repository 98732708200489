import { useState } from "react";
import Dropdown from "./Dropdown";

const tabs = ["Business Cards", "Expense management", "Automation"] as const;

type Tab = (typeof tabs)[number];

type Content = {
  [key in Tab]: {
    header: string;
    title: string;
    body: string;
  }[];
};

const content: Content = {
  "Expense management": [
    {
      header: "1. Paperless Expense Reporting",
      title: "Streamlined Submission Process",
      body: "File various expense claims, including mileage and per-diems, effortlessly through our mobile or web application. Say goodbye to manual data entry with our OCR technology that accurately extracts details from receipts. Finance teams also benefit from the ability to modify mileage rates according to their requirements.",
    },
    {
      header: "2. Streamlined Approvals & Payments",
      title: "Effortless Authorization and Reimbursement",
      body: "Simplify expense management with custom-designed approval processes. Utilize our mobile app for on-the-go approvals, accelerating employee compensation. With Moss, issuing payouts is straightforward, whether done directly through our platform or via bank payment files.",
    },
    {
      header: "3.  Effortless Book Closure",
      title: "Simplify Month-Ends with Digital Accounting",
      body: " Track spending as it occurs, with expenses auto-sorted and pre-arranged for examination. Depend on our seamless integrations with leading accounting platforms to ensure perfect alignment with your chart of accounts and dependable data transfers.",
    },
  ],
  "Business Cards": [
    {
      header: "1. Expense Control",
      title: "Full-Feature Employee Cards",
      body: "Instantly issue versatile cards to employees. Set adaptable spending limits per card. Implement custom approval rules and optional transaction audits for live monitoring and strict management of all employee card expenses.",
    },
    {
      header: "2. Receipt Tracking",
      title: "No More Lost Receipts Hassle",
      body: "Users can easily snap photos of their receipts with our app, or let the system automatically collect them via Gmail and Outlook integrations. Finance and administration teams benefit from one-click reminder setups and optional card freezing options, making adherence to policies simpler and more efficient.",
    },
    {
      header: "3. Timely Financial Closure",
      title: "Consistently Meet Month-End Deadlines",
      body: "Monitor card expenditures as they happen, with each transaction auto-categorized and pre-prepped for assessment. Leverage our robust two-way integrations with top accounting systems to maintain a synchronized chart of accounts with Moss, ensuring accurate and dependable data exports.",
    },
  ],

  Automation: [
    {
      header: "1. Smart Expense Approval",
      title: "Streamline Spending with Automated Rules",
      body: "Establish specific guidelines and controls to automate your business's financial operations. Save valuable time by eliminating manual approvals and effortlessly gathering receipts, all streamlined through our intelligent system.",
    },
    {
      header: "2. Task Delegation Simplified",
      title: "Effortlessly Assign Team Members to Tasks",
      body: "Streamline your team's workflow by auto-assigning tasks to appropriate members. Our system removes the confusion of task distribution, ensuring each responsibility is handled by the right person.",
    },
    {
      header: "3. AI Transaction Assistant",
      title: "Effortless Categorization of Transactions",
      body: "Experience the ease of categorizing transactions with AI support tailored to your business needs. Customize categories effortlessly, as our AI assistant intelligently adapts to your unique business environment.",
    },
  ],
};

const ProductTab = () => {
  const [tab, setTab] = useState<Tab>("Business Cards");
  return (
    <section
      data-aos="fade-up"
      data-aos-delay="400"
      className="hero min-h-screen"
    >
      <div className="hero-content text-center py-8 md:py-12 flex-col gap-8 pt-12 md:max-h-[calc(100vw-80px)] md:gap-16">
        <div className="max-w-2xl w-full gap-6 grid justify-center">
          <h1 className="lg:text-5xl text-4xl font-bold">
            Tailored spend workflows.
          </h1>
          <p className="py-6 lg:text-base text-sm">
            Real-time tracking of budgets across all spend, with approval and
            accounting tools tailored to your business and spend-specific flows.
          </p>
          <div
            role="tablist"
            className="tabs hidden lg:grid tabs-boxed"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            {tabs.map((t) => (
              <a
                role="tab"
                className={`tab w-max ${tab === t ? "tab-active" : ""}`}
                onClick={() => setTab(t)}
                key={t}
              >
                {t}
              </a>
            ))}
          </div>
          <div
            className="mx-auto lg:hidden z-10 "
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <Dropdown
              title={tab}
              dropdownClass="w-52"
              content={tabs.map((t) => (
                <button onClick={() => setTab(t)} key={t}>
                  {t}
                </button>
              ))}
            />
          </div>
        </div>
        <div
          className="w-full flex justify-center"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className=" sm:w-2/3 lg:1/2 max-w-5xl w-full grid gap-3">
            {content[tab].map(({ body, header, title }, i) => (
              <div
                key={tab + i}
                className="collapse collapse-arrow bg-base-200"
              >
                <input aria-label={header} type="radio" name={tab} />
                <div className="collapse-title text-xl text-left font-medium">
                  {header}
                </div>
                <div className="collapse-content text-left grid gap-6">
                  <p className="text-3xl">{title}</p>
                  <p className="text-base-content/80 text-sm">{body}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductTab;
