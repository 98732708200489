const reportsList = [
  {
    title: "9/10",
    content: "accuracy in expense report correction.",
  },
  {
    title: "52%",
    content: "boost in finance team productivity.",
  },
  {
    title: "73%",
    content: "faster employee reimbursement processing.",
  },
  {
    title: "€1850",
    content: "annual savings per employee due to enhanced efficiency.",
  },
];

const Report = () => {
  return (
    <section className="flex scroll-mt-16 bg-base-100 justify-center overflow-hidden py-8 md:py-12 ">
      <div className="grid w-full max-w-[1200px] grid-cols-4 gap-4 gap-y-8 px-5 md:grid-cols-12 md:gap-x-6 xl:px-0">
        <div className="col-span-4 grid grid-cols-4 gap-y-12 md:col-span-12 md:grid-cols-12 md:gap-6 lg:gap-y-[120px]">
          <div
            className="col-span-4 flex flex-col gap-3 md:col-span-7 xl:col-span-6 aos-init aos-animate"
            data-aos="fade-up"
          >
            <p className="text-xl font-medium lg:text-4xl">
              Maximize Returns with Dynt
            </p>
            <p className="text-sm tracking-[.16px] text-base-content/90">
              Users of Dynt witness remarkable ROI gains, achieving positive
              returns in a mere 5 months. In 3 years, leverage enhanced spending
              controls, replace manual processes with automation, and unify all
              expenditures on a single platform to potentially reach a 3.5x ROI.
            </p>
          </div>
          <div
            className="col-span-4 grid grid-cols-4 gap-4 md:col-span-12 md:grid md:grid-cols-12 md:gap-6 aos-init aos-animate"
            data-aos="fade-up"
          >
            {reportsList.map(({ title, content }) => (
              <div
                className="col-span-2 flex flex-col gap-3 md:col-span-3"
                key={title}
              >
                <p className="self-stretch text-4xl font-medium">{title}</p>
                <p className=" self-stretch text-xs tracking-[.28px] text-base-content/90 md:text-sm md:tracking-[.16px] ">
                  {content}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Report;
